export namespace SupportChat {
    export const hideBubble = () => {
        const chat = document.getElementsByClassName('cc-widget-outer-container')[0]
        if (chat) {
            chat.setAttribute('style', 'visibility: hidden !important;')
        }
    }

    export const showBubble = () => {
        const chat = document.getElementsByClassName('cc-widget-outer-container')[0]
        if (chat) {
            chat.setAttribute('style', 'visibility: visible !important;')
        }
    }
}
